import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/homepage.vue')
    },
    {
        path: '/p/introduction/',
        name: 'introduction',
        component: () => import('@/views/introduction.vue')
    },
    {
        path: '/p/tutorials/',
        name: 'tutorials',
        component: () => import('@/views/tutorials.vue'),
        children: [
            {
                path: '', 
                name: 'tutorials-loop', 
                components: {
                    inner: () => import('../views/tutorials/tutorials_loop.vue'),
                }
            },
            {
                path: 't/how-create-company/', 
                name: 'tutorials-create-company', 
                components: {
                    inner: () => import('../views/tutorials/how_create_company.vue'),
                }
            },
            {
                path: 't/how-edit-company/', 
                name: 'tutorials-edit-company', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_company.vue'),
                }
            },
            {
                path: 't/how-delete-company/', 
                name: 'tutorials-delete-company', 
                components: {
                    inner: () => import('../views/tutorials/how_delete_company.vue'),
                }
            },
            {
                path: 't/how-create-product/', 
                name: 'tutorials-create-product', 
                components: {
                    inner: () => import('../views/tutorials/how_create_product.vue'),
                }
            },
            {
                path: 't/how-edit-product/', 
                name: 'tutorials-edit-product', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_product.vue'),
                }
            },
            {
                path: 't/how-delete-product/', 
                name: 'tutorials-delete-product', 
                components: {
                    inner: () => import('../views/tutorials/how_delete_product.vue'),
                }
            },
            {
                path: 't/how-add-pipeline-stage/', 
                name: 'tutorials-add-pipeline-stage', 
                components: {
                    inner: () => import('../views/tutorials/how_add_pipeline_stage.vue'),
                }
            },
            {
                path: 't/how-edit-pipeline-stage/', 
                name: 'tutorials-edit-pipeline-stage', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_pipeline_stage.vue'),
                }
            },
            {
                path: 't/how-remove-pipeline-stage/', 
                name: 'tutorials-remove-pipeline-stage', 
                components: {
                    inner: () => import('../views/tutorials/how_remove_pipeline_stage.vue'),
                }
            },
            {
                path: 't/how-create-role/', 
                name: 'tutorials-create-role', 
                components: {
                    inner: () => import('../views/tutorials/how_create_role.vue'),
                }
            },
            {
                path: 't/how-change-role-privilege/', 
                name: 'tutorials-change-role-privilege', 
                components: {
                    inner: () => import('../views/tutorials/how_change_role_privilege.vue'),
                }
            },
            {
                path: 't/how-assign-personnel-role/', 
                name: 'tutorials-assign-personnel-role', 
                components: {
                    inner: () => import('../views/tutorials/how_assign_personnel_role.vue'),
                }
            },
            {
                path: 't/how-edit-role/', 
                name: 'tutorials-edit-role', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_role.vue'),
                }
            },
            {
                path: 't/how-delete-role/', 
                name: 'tutorials-delete-role', 
                components: {
                    inner: () => import('../views/tutorials/how_delete_role.vue'),
                }
            },
            {
                path: 't/how-request-consultation/', 
                name: 'tutorials-request-consultation', 
                components: {
                    inner: () => import('../views/tutorials/how_request_consultation.vue'),
                }
            },
            {
                path: 't/how-create-consultation/', 
                name: 'tutorials-create-consultation', 
                components: {
                    inner: () => import('../views/tutorials/how_create_consultation.vue'),
                }
            },
            {
                path: 't/how-edit-consultation/', 
                name: 'tutorials-edit-consultation', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_consultation.vue'),
                }
            },
            {
                path: 't/how-create-assignment/', 
                name: 'tutorials-create-assignment', 
                components: {
                    inner: () => import('../views/tutorials/how_create_assignment.vue'),
                }
            },
            {
                path: 't/how-edit-assignment/', 
                name: 'tutorials-edit-assignment', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_assignment.vue'),
                }
            },
            {
                path: 't/how-delete-assignment/', 
                name: 'tutorials-delete-assignment', 
                components: {
                    inner: () => import('../views/tutorials/how_delete_assignment.vue'),
                }
            },
            {
                path: 't/how-create-client/', 
                name: 'tutorials-create-client', 
                components: {
                    inner: () => import('../views/tutorials/how_create_client.vue'),
                }
            },
            {
                path: 't/how-send-invite-client/', 
                name: 'tutorials-send-invite-client', 
                components: {
                    inner: () => import('../views/tutorials/how_send_invite_client.vue'),
                }
            },
            {
                path: 't/how-edit-client/', 
                name: 'tutorials-edit-client', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_client.vue'),
                }
            },
            {
                path: 't/how-delete-client/', 
                name: 'tutorials-delete-client', 
                components: {
                    inner: () => import('../views/tutorials/how_delete_client.vue'),
                }
            },
            {
                path: 't/how-create-handbook/', 
                name: 'tutorials-create-handbook', 
                components: {
                    inner: () => import('../views/tutorials/how_create_handbook.vue'),
                }
            },
            {
                path: 't/how-add-pages-handbook/', 
                name: 'tutorials-add-pages-handbook', 
                components: {
                    inner: () => import('../views/tutorials/how_add_pages_handbook.vue'),
                }
            },
            {
                path: 't/how-edit-handbook/', 
                name: 'tutorials-edit-handbook', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_handbook.vue'),
                }
            },
            {
                path: 't/how-delete-handbook/', 
                name: 'tutorials-delete-handbook', 
                components: {
                    inner: () => import('../views/tutorials/how_delete_handbook.vue'),
                }
            },
            {
                path: 't/how-create-personnel/', 
                name: 'tutorials-create-personnel', 
                components: {
                    inner: () => import('../views/tutorials/how_create_personnel.vue'),
                }
            },
            {
                path: 't/how-send-invite-personnel/', 
                name: 'tutorials-send-invite-personnel', 
                components: {
                    inner: () => import('../views/tutorials/how_send_invite_personnel.vue'),
                }
            },
            {
                path: 't/how-edit-personnel/', 
                name: 'tutorials-edit-personnel', 
                components: {
                    inner: () => import('../views/tutorials/how_edit_personnel.vue'),
                }
            },
            {
                path: 't/how-delete-personnel/', 
                name: 'tutorials-delete-personnel', 
                components: {
                    inner: () => import('../views/tutorials/how_delete_personnel.vue'),
                }
            },
        ],
    },
    {
        path: '/p/integrations/',
        name: 'integrations',
        component: () => import('@/views/integrations.vue'),
        children: [
            {
                path: '', 
                name: 'integrations-basics', 
                components: {
                    inner: () => import('@/views/integrations/basics.vue'),
                }
            },
            {
                path: 'i/slack/', 
                name: 'integrations-slack', 
                components: {
                    inner: () => import('@/views/integrations/slack.vue'),
                }
            },
            {
                path: 'i/discord/', 
                name: 'integrations-discord', 
                components: {
                    inner: () => import('@/views/integrations/discord.vue'),
                }
            },
            {
                path: 'i/zapier/', 
                name: 'integrations-zapier', 
                components: {
                    inner: () => import('@/views/integrations/zapier.vue'),
                }
            },
            {
                path: 'i/make/', 
                name: 'integrations-make', 
                components: {
                    inner: () => import('@/views/integrations/make.vue'),
                }
            },
            {
                path: 'i/hubspot/', 
                name: 'integrations-hubspot', 
                components: {
                    inner: () => import('@/views/integrations/hubspot.vue'),
                }
            },
            {
                path: 'i/asana/', 
                name: 'integrations-asana', 
                components: {
                    inner: () => import('@/views/integrations/asana.vue'),
                }
            },
            {
                path: 'i/trello/', 
                name: 'integrations-trello/', 
                components: {
                    inner: () => import('@/views/integrations/trello.vue'),
                }
            },
            {
                path: 'i/monday/', 
                name: 'integrations-monday/', 
                components: {
                    inner: () => import('@/views/integrations/monday.vue'),
                }
            },
            {
                path: 'i/notion/', 
                name: 'integrations-notion/', 
                components: {
                    inner: () => import('@/views/integrations/notion.vue'),
                }
            },
            {
                path: 'i/airtable/', 
                name: 'integrations-airtable/', 
                components: {
                    inner: () => import('@/views/integrations/airtable.vue'),
                }
            },
            {
                path: 'i/mailchimp/', 
                name: 'integrations-mailchimp/', 
                components: {
                    inner: () => import('@/views/integrations/mailchimp.vue'),
                }
            },
            {
                path: 'i/activecampaign/', 
                name: 'integrations-activecampaign/', 
                components: {
                    inner: () => import('@/views/integrations/activecampaign.vue'),
                }
            },
            {
                path: 'i/brevo/', 
                name: 'integrations-brevo/', 
                components: {
                    inner: () => import('@/views/integrations/brevo.vue'),
                }
            },
        ],
    },
    {
        path: '/p/api/',
        name: 'api',
        component: () => import('@/views/api.vue'),
        children: [
            {
                path: '', 
                name: 'api-basics', 
                components: {
                    inner: () => import('../views/apis/basics.vue'),
                }
            },
            {
                path: 'a/company/', 
                name: 'api-company', 
                components: {
                    inner: () => import('../views/apis/company.vue'),
                }
            },
            {
                path: 'a/products/', 
                name: 'api-products', 
                components: {
                    inner: () => import('../views/apis/products.vue'),
                }
            },
            {
                path: 'a/roles/', 
                name: 'api-roles', 
                components: {
                    inner: () => import('../views/apis/roles.vue'),
                }
            },
            {
                path: 'a/consultations/', 
                name: 'api-consultations', 
                components: {
                    inner: () => import('../views/apis/consultations.vue'),
                }
            },
            {
                path: 'a/assignments/', 
                name: 'api-assignments', 
                components: {
                    inner: () => import('../views/apis/assignments.vue'),
                }
            },
            {
                path: 'a/clients/', 
                name: 'api-clients', 
                components: {
                    inner: () => import('../views/apis/clients.vue'),
                }
            },
            {
                path: 'a/personnel/', 
                name: 'api-personnel', 
                components: {
                    inner: () => import('../views/apis/personnel.vue'),
                }
            },
            {
                path: 'a/webhooks/', 
                name: 'api-webhooks', 
                components: {
                    inner: () => import('../views/apis/webhooks.vue'),
                }
            },
        ],
    },
    {
        path: '/p/workflows/',
        name: 'workflows',
        component: () => import('@/views/workflows.vue')
    },
    {
        path: '/p/resources/',
        name: 'resources',
        component: () => import('@/views/resources.vue')
    },
    {
        path: '/p/faqs/',
        name: 'faqs',
        component: () => import('@/views/faqs.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
