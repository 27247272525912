
/*
*
* https://github.com/xriley/CoderDocs-Theme
* https://themes.3rdwavemedia.com/demo/bs5/coderdocs/index.html
*
*
*/

//
//
export const CONSTANTS = {

    'DEBUG_MODE': false,

    'APP_NAME': 'Hierarch.io',
    'APP_SLOGAN': 'Help Center, Documentations, etc.',
    'APP_DESCRIPTION': 'The place to search for help.',
    'THEME': 'CoderDocs-Theme',

    'API_DOMAIN':  'https://api.hierarch.io',

    'SHOW_SIDEBAR': true,

    'tutorials_array': [
        {
            'link': '/p/tutorials/t/how-create-company/',
            'name': 'How to create a company?',
        },
        {
            'link': '/p/tutorials/t/how-edit-company/',
            'name': 'How to edit company information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-company/',
            'name': 'How to delete a company?',
        },
        {
            'link': '/p/tutorials/t/how-create-product/',
            'name': 'How to create a product?',
        },
        {
            'link': '/p/tutorials/t/how-edit-product/',
            'name': 'How to edit product information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-product/',
            'name': 'How to delete a product?',
        },
        {
            'link': '/p/tutorials/t/how-add-pipeline-stage/',
            'name': 'How to add a pipeline stage?',
        },
        {
            'link': '/p/tutorials/t/how-edit-pipeline-stage/',
            'name': 'How to edit a pipeline stage?',
        },
        {
            'link': '/p/tutorials/t/how-remove-pipeline-stage/',
            'name': 'How to remove a pipeline stage?',
        },
        {
            'link': '/p/tutorials/t/how-create-role/',
            'name': 'How to create a new role?',
        },
        {
            'link': '/p/tutorials/t/how-edit-role/',
            'name': 'How to edit role information?',
        },
        {
            'link': '/p/tutorials/t/how-change-role-privilege/',
            'name': 'How to change role privileges?',
        },
        {
            'link': '/p/tutorials/t/how-assign-personnel-role/',
            'name': 'How to assign personnel to a role?',
        },
        {
            'link': '/p/tutorials/t/how-delete-role/',
            'name': 'How to delete role?',
        },
        {
            'link': '/p/tutorials/t/how-request-consultation/',
            'name': 'How to request a consultation [as a client]?',
        },
        {
            'link': '/p/tutorials/t/how-create-consultation/',
            'name': 'How to create a consultation [as an owner/personnel]?',
        },
        {
            'link': '/p/tutorials/t/how-edit-consultation/',
            'name': 'How to edit a consultation?',
        },
        {
            'link': '/p/tutorials/t/how-create-assignment/',
            'name': 'How to create an assignment record?',
        },
        {
            'link': '/p/tutorials/t/how-edit-assignment/',
            'name': 'How to edit assignment information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-assignment/',
            'name': 'How to delete an assignment record?',
        },
        {
            'link': '/p/tutorials/t/how-create-client/',
            'name': 'How to create a client record?',
        },
        {
            'link': '/p/tutorials/t/how-send-invite-client/',
            'name': 'How to send an invite to a client?',
        },
        {
            'link': '/p/tutorials/t/how-edit-client/',
            'name': 'How to edit client information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-client/',
            'name': 'How to delete a client record?',
        },
        {
            'link': '/p/tutorials/t/how-create-handbook/',
            'name': 'How to create a handbook?',
        },
        {
            'link': '/p/tutorials/t/how-add-pages-handbook/',
            'name': 'How to add pages to a handbook?',
        },
        {
            'link': '/p/tutorials/t/how-edit-handbook/',
            'name': 'How to edit handbook information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-handbook/',
            'name': 'How to delete a handbook?',
        },
        {
            'link': '/p/tutorials/t/how-create-personnel/',
            'name': 'How to create a personnel record?',
        },
        {
            'link': '/p/tutorials/t/how-send-invite-personnel/',
            'name': 'How to send an invite to a personnel?',
        },
        {
            'link': '/p/tutorials/t/how-edit-personnel/',
            'name': 'How to edit personnel information?',
        },
        {
            'link': '/p/tutorials/t/how-delete-personnel/',
            'name': 'How to delete a personnel record?',
        },
    ],

}

//
//
export const change_title_tag = function(v){
    document.title = v + ' | ' + CONSTANTS['APP_NAME']
}

//
//
export const toggle_sidebar = function(){
    CONSTANTS['SHOW_SIDEBAR'] = !CONSTANTS['SHOW_SIDEBAR']
    console.log(CONSTANTS['SHOW_SIDEBAR'])
    set_cookie(
        'sidebar_visibility',
        CONSTANTS['SHOW_SIDEBAR'],
        1000
    )
}

//
//
export const get_sidebar = function(){
    //console.log(CONSTANTS['SHOW_SIDEBAR'])
    return CONSTANTS['SHOW_SIDEBAR']
}

//
//
export const add_class = function(_id,_class){

    let el = document.getElementById(_id);
    el.classList.add(_class)

}

//
//
export const remove_class = function(_id,_class){

    let el = document.getElementById(_id);
    el.classList.remove(_class)

}

//
//
export const set_cookie = function(cname, cvalue, expiry_days) {
    const d = new Date();
    d.setTime(d.getTime() + (expiry_days * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//
//
export const get_cookie = function(cname){

    // returns value of cookie['']

    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}
//
//
export const if_cookie_exists = function(cname){

    // returns true or false

    let the_cookie = get_cookie(cname);
    if (the_cookie != "") {
        return true
    } else {
        return false
    }
}



