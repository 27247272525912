
import { createStore } from 'vuex'
import * as wt from '../watchtower'

export default createStore({
    state: {

        'CONSTANTS': wt.CONSTANTS,

        'change_title_tag': wt.change_title_tag,

        'toggle_sidebar': wt.toggle_sidebar,

        'show_sidebar': wt.CONSTANTS['SHOW_SIDEBAR'],
        'get_sidebar': wt.get_sidebar,

        'add_class': wt.add_class,
        'remove_class': wt.remove_class,

        'tutorials_array': wt.CONSTANTS['tutorials_array'],

        'set_cookie': wt.set_cookie,
        'get_cookie': wt.get_cookie,
        'if_cookie_exists': wt.if_cookie_exists,

    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
